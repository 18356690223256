import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

function Claim({ uniqueData }) {
  // Modal open state
  const loadModal = uniqueData.claim_modal;
  const [modal, setModal] = useState(loadModal === "true" ? true : false);
  const [contentmodal, setContentModal] = useState(false);
  const [warning, setWarningModal] = useState(
    loadModal === "true" ? true : false
  );

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  const content_toggle = () => setContentModal(!contentmodal);
  const warning_toggle = () => setWarningModal(!warning);

  // Claim form
  const [claimer_name, setName] = useState("");
  const [claimer_email, setEmail] = useState("");
  const [pass, setPasscode] = useState("");
  const [exist_pass, setExitPasscode] = useState(uniqueData.pass);
  const handleFormSubmit = (event) => {
    event.preventDefault();

    const dataToClaim =
      exist_pass !== ""
        ? {
            pass: exist_pass,
            claimer_email,
            claimer_name,
          }
        : {
            pass,
            claimer_email,
            claimer_name,
          };
    // console.log(dataToClaim);
    fetch(
      "https://verifier.thegrowhub.co/unique-product/" +
        uniqueData.uid +
        "/claim",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(dataToClaim),
      }
    )
      // .then((data) => data.json())
      .then((data) => {
        // console.log(data)
        // console.log(data.status)
        if (data.status === 401) {
          Swal.fire({
            title: "Error",
            text: "Invalid passcode",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#4da045",
          });
        } else if (data.status === 400) {
          Swal.fire({
            title: "Error",
            text: "Please check your passcode and try again.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#4da045",
          });
        } else if (data.status === 404) {
          Swal.fire({
            title: "Error",
            text: "Unable to verify product: Invalid product code.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#4da045",
          });
        } else if (data.status === 412) {
          Swal.fire({
            title: "Error",
            text: "Unable to verify product. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#4da045",
          });
        } else if (data.status === 500) {
          Swal.fire({
            title: "Error",
            text: "Something went wrong. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#4da045",
          });
        } else {
          Swal.fire({
            title: "Thank you!",
            text: "Claim is approved",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#4da045",
          }).then(function () {
            window.location.reload();
          });
        }
        return data;
      });
  };

  return (
    <Container className="mw-100 claim">
      <Row>
        <Col>
          {/* <h1>
            For This Item{" "}
            
            {uniqueData.scan_count && (
              <span className="count-number"><FontAwesomeIcon icon={faEye} className="scan-count" />{uniqueData.scan_count}</span>
            )}
          </h1> */}
          {/* <p className="mb-0">Item ID: {uniqueData.uid}</p>    */}

          {/* {uniqueData.claimable === false && (
                        <div className="claim-info">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/images/prchased-badge.png"
                                }
                                alt="Claim Logo"
                                className="img-fluid mr-4"
                                width={60}
                            />
                            <div>
                                {uniqueData.owner_name !== "" && (
                                    <span className="text-start">
                                        By: {uniqueData.owner_name}
                                    </span>
                                )}
                                {uniqueData.claimed_date !== "" && (
                                    <span className="text-start">
                                        Date:{" "}
                                        {format(
                                            new Date(uniqueData.claimed_date),
                                            "dd/MM/yyyy"
                                        )}
                                    </span>
                                )}
                            </div>
                        </div>
                    )} */}
          {uniqueData.claimable === true && (
            <div className="d-flex justify-content-center align-items-center">
              <Button onClick={toggle} className="mt-2">
                Check Authenticity
              </Button>
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="dir-icon"
                onClick={content_toggle}
              />
            </div>
          )}
        </Col>
      </Row>

      {/* warning modal if claim modal is true and product is already claim */}
      {uniqueData.claimable === false && uniqueData.claim_modal === "true" && (
        <Modal isOpen={warning} toggle={warning_toggle} className="claim-modal">
          <ModalHeader className="pb-0">Authenticity Check</ModalHeader>
          <ModalBody className="px-4">
            <p className="mb-4 text-center">
              Product has already been verified. If you did not perform this
              authenticity check, please contact your distributor and feedback
              to us
            </p>
          </ModalBody>
        </Modal>
      )}

      {/* Claim modal */}
      {uniqueData.claimable === true && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="claim-modal"
          id="claim-form"
        >
          <ModalHeader className="pb-0">Authenticity Check</ModalHeader>
          <ModalBody className="px-4">
            <p className="mb-4 text-center">
              Every item comes with a secret passcode within the packaging.
              <br /> Enter your information with the passcode below to verify
              authenticity.
            </p>
            <form method="post" action="#" onSubmit={handleFormSubmit}>
              <input
                type="text"
                placeholder="Your Public Display Name"
                className="d-block claim-name text-center"
                value={claimer_name}
                name="name"
                onChange={(event) => setName(event.target.value)}
              ></input>
              <input
                type="email"
                placeholder="Your Email Address"
                className="d-block claim-email text-center"
                value={claimer_email}
                name="email"
                onChange={(event) => setEmail(event.target.value)}
              ></input>
              <input
                type="text"
                placeholder="Secret Passcode"
                className="d-block claim-passcode text-center"
                value={exist_pass !== "" ? exist_pass : pass}
                name="passcode"
                onChange={
                  exist_pass !== ""
                    ? (event) => setExitPasscode(event.target.value)
                    : (event) => setPasscode(event.target.value)
                }
                required
              ></input>
              <Button
                type="submit"
                className="claim-button btn btn-secondary"
                onClick={handleFormSubmit}
              >
                Check Authenticity
              </Button>
            </form>
          </ModalBody>
        </Modal>
      )}

      {/* Verify modal */}
      {uniqueData.claimable === true && (
        <Modal
          isOpen={contentmodal}
          toggle={content_toggle}
          className="claim-modal"
        >
          <ModalHeader className="pb-0">Authenticity Check</ModalHeader>
          <ModalBody className="px-4">
            <p>
              Each product comes with a secret passcode. This passcode can be
              used to complete the authenticity check.
            </p>
            <p>
              Follow the enclosed instructions to proceed with the authenticity
              check. You may need to either:
            </p>
            <ul>
              <li>tap on the NFC card,</li>
              <li>scan the QR code, or</li>
              <li>scratch to reveal the passcode and complete the process</li>
            </ul>
            <p>
              By doing this, you not only help us to verify the purchase, but
              also provide more accurate data for combating counterfeit
              activities.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button className="main-btn btn-md" onClick={content_toggle}>
              Got It
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {/* : (
                <Modal
                    isOpen={contentmodal}
                    toggle={content_toggle}
                    className="claim-modal"
                >
                    <ModalHeader className="pb-0">
                        Authenticity Check
                    </ModalHeader>
                    <ModalBody>
                        <p className="text-center">
                            If you are trying to check the authenticity of this
                            product and you are seeing this, this product is
                            possibly fake.
                        </p>
                        <p className="text-center">
                            Contact your distributor and send us your feedback
                            below.
                        </p>
                    </ModalBody>
                    <ModalFooter className="justify-content-between">
                        <Link to="/feedback">
                            <Button className="main-btn btn-md">
                                Feedback
                            </Button>
                        </Link>
                        <Button
                            className="main-btn btn-md"
                            onClick={content_toggle}
                        >
                            Got it
                        </Button>
                    </ModalFooter>
                </Modal>
            )} */}
    </Container>
  );
}

export default Claim;
