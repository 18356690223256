import React, { useEffect, useState, useRef } from "react";
import { ethers } from "ethers";
import Swal from "sweetalert2";

// ABIs
import productABI from "./api/IntegratedProduct.20221107154500";
import registryABI from "./api/abis";

// Components
import Header from "./components/Header";
import Warning from "./components/Warning";
import Claim from "./components/Claim";
import ItemVerified from "./components/ItemVerified";
import Product from "./components/Product";
import Info from "./components/Info";
import Trace from "./components/Trace";
import More from "./components/More";
import Footer from "./components/Footer";

const PUBLIC_JSON_RPC_URL =
  "https://polygon-mainnet.g.alchemy.com/v2/8hfsFapktWtheRLHVazfWELGk10wCn_q";
// TODO: replace for deployment
const INFORMATION_REGISTRY_CONTRACT_ADDRESS =
  "0xE0A5005f5bbC3BbD59e291409269411127606782";
// locale should be fixed according to the preferred display format
const LOCALE = "en-sg";
const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getParams = function (url) {
  let params = {};
  let parser = document.createElement("a");
  parser.href = url;
  let query = parser.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

function App() {
  const [metadata, setMetaData] = useState([]);
  const [envData, setEnvData] = useState([]);
  const [chain, setChain] = useState([]);
  const [uniqueData, setUniqueData] = useState([]);
  const params = getParams(window.location.href);

  const externalLink = `https://opensea.io/assets/matic/${params.contract}/${params.id}`;
  // for STAGING
  // const externalLink = `https://testnets.opensea.io/assets/mumbai/${params.contract}/${params.id}`;

  // MetaData
  const fetchData = async (contract, tokenId) => {
    if (contract && tokenId) {
      let uri = await contract.uri(tokenId);
      uri = uri.replace("{id}", tokenId);
      // console.log(uri);
      const response = await fetch(uri);
      if (response.status === 200) {
        const metadata = await response.json();
        setMetaData(metadata);
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Unable to retrieve information",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#4da045",
      });
    }
  };

  //Environmental Data
  const fetchEnv = async (contract, tokenId) => {
    try {
      return await contract.getLogs(tokenId);
    } catch (error) {
      //   console.log(error);
      Swal.fire({
        title: "Error",
        text: "Unable to retrieve information",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#4da045",
      });
    }
  };

  const getEnvDetail = async (logs) => {
    if (logs) {
      try {
        return await logs.map(async ([unixTimestamp, contents]) => {
          // console.debug(`[ loadEnvironmentalData ] #${index}: (timestamp=${unixTimestamp}, contents=${contents})`)

          // the timestamp needs to be constructed separately, cause the only control we have when using
          // `toLocateString` is the locale
          const timestamp = new Date(unixTimestamp * 1000);
          const dateComponent = timestamp.toLocaleString(LOCALE, {
            timeZone: localTimeZone,
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          const timeComponent = timestamp.toLocaleString(LOCALE, {
            timeZone: localTimeZone,
            hour: "numeric",
            minute: "numeric",
            hourCycle: "h24",
          });
          // contents are assumed to be JSON
          const data = JSON.parse(contents);

          // versioned parsing
          // let key = 'undefined',
          //     value = 'undefined'
          // if (data.version === '0.0.1') {
          //     key = data.key || key
          //     value = data.value || value
          // } else {
          //     throw Error(`Unsupported log version ${data.version}`)
          // }
          // console.log(data)
          return {
            time: timeComponent,
            date: dateComponent,
            data: data,
          };
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  //Supply Chain Data
  const fetchChain = async (contract, registry, tokenId) => {
    try {
      // TODO: replace filtering of all TransferSingle events with more
      // appropriate solution
      const allTransfers = await contract.queryFilter(
        contract.filters.TransferSingle()
      );

      const relevantTransfers = allTransfers.filter(
        (event) =>
          ethers.utils.defaultAbiCoder
            .decode(["uint256", "uint256"], event.data)[0]
            .toNumber() === parseInt(tokenId)
      );

      const transfers = [...relevantTransfers].reverse();
      console.debug(
        `[ loadSupplyChainTrace ] found ${transfers.length} transfers`
      );
      return transfers;
    } catch (error) {
      console.log(
        "[ loadSupplyChainTrace ] encountered error processing one of the transfer events"
      );
    }
  };

  const getChainDetail = async (transfers, registry, contract) => {
    try {
      // console.log(transfers);
      return await transfers.map(async (transfer) => {
        const sender = ethers.utils.defaultAbiCoder.decode(
          ["address"],
          transfer.topics[2]
        )[0];
        const recipient = ethers.utils.defaultAbiCoder.decode(
          ["address"],
          transfer.topics[3]
        )[0];
        // console.debug(`[ loadSupplyChainTrace ] event: ${JSON.stringify(transfer.topics)}`);
        const value = ethers.utils.defaultAbiCoder.decode(
          ["uint256", "uint256"],
          transfer.data
        )[1];

        const id = ethers.utils.defaultAbiCoder.decode(
          ["uint256", "uint256"],
          transfer.data
        )[0];

        const balance = await contract.balanceOf(recipient, id);

        const transferQty = value.toNumber();
        const balanceUnits = balance.toNumber();

        const block = await transfer.getBlock();
        const timestamp = new Date(block.timestamp * 1000);
        const date = timestamp.toLocaleString(LOCALE, {
          day: "numeric",
          month: "short",
        });
        const time = timestamp.toLocaleString(LOCALE, {
          timeZone: localTimeZone,
          hour: "numeric",
          minute: "numeric",
          hourCycle: "h24",
        });
        // look up the designation of the recipient
        let designation = "UNKNOWN";
        if (await registry.hasDesignation(recipient)) {
          // the entity name is the first element of the designation
          designation = (await registry.getDesignation(recipient))[0];
        }

        let senderDesignation;
        if (await registry.hasDesignation(sender)) {
          senderDesignation = (await registry.getDesignation(sender))[0];
        }

        return {
          time: time,
          date: date,
          senderDesignation: senderDesignation,
          designation: designation,
          transferQty,
          balanceUnits,
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const params = getParams(window.location.href);
    const ContractAddress = params.contract;
    const provider = new ethers.providers.JsonRpcProvider(PUBLIC_JSON_RPC_URL);

    // read-only
    const product = new ethers.Contract(ContractAddress, productABI, provider);
    const registry = new ethers.Contract(
      INFORMATION_REGISTRY_CONTRACT_ADDRESS,
      registryABI,
      provider
    );
    const tokenId = params.id;
    if (tokenId === "") {
      Swal.fire({
        title: "Invalid",
        text: "Invalid link. Please check the URL.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#4da045",
      });
    }
    if (ContractAddress === "") {
      Swal.fire({
        title: "Invalid",
        text: "Invalid link. Please check the URL.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#4da045",
      });
    }

    // call Metadata function
    fetchData(product, tokenId).catch(console.error);

    // call Chain function
    fetchChain(product, registry, tokenId).then((transfers) => {
      getChainDetail(transfers, registry, product).then((chainDetail) => {
        if (chainDetail) {
          Promise.all(chainDetail).then((chainValues) => {
            setChain(chainValues);
          });
        }
      });
    });

    // call Environmental function

    fetchEnv(product, tokenId).then((value) => {
      if ((product, tokenId)) {
        getEnvDetail(value).then((envDetail) => {
          if (envDetail) {
            Promise.all(envDetail).then((envValues) => {
              setEnvData(envValues);
            });
          }
        });
      }
    });

    // eslint-disable-next-line
  }, []);

  //unique Id
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      if (params.uid !== undefined) {
        async function getUniqueData() {
          const res = await fetch(
            `https://verifier.thegrowhub.co/unique-product/${params.uid}`
          );
          const data = res.json();
          // console.log({ data });
          if (data !== null) {
            const detailData = {
              scan_count: data.scan_count ? data.scan_count : "",
              claimable: data.claimable && data.claimable,
              owner_name: data.owner ? data.owner.name : "",
              claimed_date: data.claimed_date ? data.claimed_date : "",
              warning_message: data.warning_message ? data.warning_message : "",
              uid: params.uid,
              pass: params.passcode ? params.passcode : "",
              claim_modal: params.claimModal ? params.claimModal : "",
            };
            setUniqueData(detailData);
            // console.log(detailData);
          }
        }
        getUniqueData();
        return () => {
          effectRan.current = true;
        };
      }
    }
  }, [params.uid, params.passcode, params.claimModal]);

  return (
    <div className="App">
      <Header />

      {uniqueData.warning_message !== "" ? (
        metadata?.image && <Warning uniqueData={uniqueData} />
      ) : uniqueData.claimable ? (
        metadata?.image && <Claim uniqueData={uniqueData} />
      ) : (
        <ItemVerified uniqueData={uniqueData} />
      )}
      {uniqueData?.length === 0 && metadata?.image && <ItemVerified />}

      {metadata && (
        <>
          <Product data={metadata} externalLink={externalLink} />
          <Info data={metadata} />
          <Trace data={metadata} chain={chain} externalLink={externalLink} />
          <More data={metadata} envData={envData} />
        </>
      )}

      <Footer />
    </div>
  );
}

export default App;
