import React from "react";
import { Navbar, NavItem, NavLink, Nav } from "reactstrap";

function Header() {
  const handleClickProduct = () => {
    const element = document.getElementById("productSection");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickProductInfo = () => {
    const element = document.getElementById("infoSection");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickTrace = () => {
    const element = document.getElementById("traceSection");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickMore = () => {
    const element = document.getElementById("moreSection");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Navbar light expand="md" fixed="top">
      <Nav className="mr-auto" navbar>
        <NavItem>
          <NavLink onClick={handleClickProduct}>Product</NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={handleClickProductInfo}>Product Info</NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={handleClickTrace}>Footprints</NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={handleClickMore}>More Info</NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
}

export default Header;

// test
